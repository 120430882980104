import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { Menu, Link, Icon } from "../../ui";
import { fullscreenAdapter, WAGER_PROGRESS_WIDGET } from "../../lib";
import UserProgressWidget from "./widgets/UserProgress";
import WagerProgressWidget from "./widgets/WagerProgress";
import TournamentsWidget from "./widgets/Tournaments";
import PendingWithdrawalsWidget from "./widgets/PendingWithdrawals";
import LicenseCaption from "./LicenseCaption";
import FullscreenButton from "./FullscreenButton";
import AddToFavoritesButton from "./AddToFavoritesButton";
import ErrorBoundary from "./ErrorBoundary";
import "./MenuForm.css";

function MenuForm ({
  gameOptions,
  onHomeClick,
  onFullscreenClick,
  onReloadClick,
  onSupportClick,
  onAddToFavorites,
  onRemoveFromFavorites,
  sendSessionForVwo,
}) {
  const { t } = useTranslation();
  const [ loading, setLoading ] = useState(true);
  const loadingCountRef = React.useRef(0);
  const faildCallbackRef = React.useRef(0);

  useEffect(() => {
    const widgets = [
      WAGER_PROGRESS_WIDGET,
    ];

    loadingCountRef.current = 0;

    for (const widget of widgets) {
      if (gameOptions.showUserWidgets(widget)) {
        loadingCountRef.current += 1;
      }
    }

    if (loadingCountRef.current === 0) {
      setLoading(false);
    } else {
      setTimeout(() => setLoading(false), 300);
    }

    faildCallbackRef.current = () => {
      setLoading(false);
    };

    return () => {
      faildCallbackRef.current = null;
    }
  }, [ gameOptions ]);

  const showFullscreen = () => {
    return fullscreenAdapter.isEnabled();
  };

  const onWidgetLoad = () => {
    // loadingCountRef.current -= 1;

    // if (loadingCountRef.current <= 0) {
    if (faildCallbackRef.current) {
      faildCallbackRef.current();
    }
    // }
  };

  const cls = cx("l6r__menu-content", {
    "l6r__menu-content--loading": loading,
  });

  return (
    <Fragment>
      <div className={cls}>
        <div className="l6r__menu-buttons-block">
          <Menu>
            <Menu.Group>
              <Menu.Item>
                <Link
                  className="l6r__btn-close"
                  title={t("home")}
                  text={t("home")}
                  icon={Icon.Home}
                  onClick={onHomeClick}
                  small
                />
              </Menu.Item>

              <Menu.Item>
                <Link
                  className="l6r__btn-reload"
                  title={t("reload")}
                  text={t("reload")}
                  icon={Icon.Reload}
                  onClick={onReloadClick}
                  small
                />
              </Menu.Item>

              {showFullscreen() && (
                <Menu.Item>
                  <FullscreenButton onClick={onFullscreenClick} />
                </Menu.Item>
              )}
            </Menu.Group>

            <Menu.Item>
              <Menu.Group>
                {gameOptions.showFavoritButton() && (
                  <Menu.Item>
                    <AddToFavoritesButton
                      gameOptions={gameOptions}
                      onAddToFavorites={onAddToFavorites}
                      onRemoveFromFavorites={onRemoveFromFavorites}
                    />
                  </Menu.Item>
                )}

                {gameOptions.showSupportButton() && (
                  <Menu.Item>
                    <Link
                      className="l6r__btn-chat"
                      title={t("support")}
                      text={t("support")}
                      icon={Icon.HelpChat}
                      onClick={onSupportClick}
                      small
                    />
                  </Menu.Item>
                )}
              </Menu.Group>
            </Menu.Item>
          </Menu>
        </div>

        <ErrorBoundary>
          <UserProgressWidget
            gameOptions={gameOptions}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <WagerProgressWidget
            onLoad={onWidgetLoad}
            gameOptions={gameOptions}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <PendingWithdrawalsWidget
            onLoad={onWidgetLoad}
            onReload={onReloadClick}
            gameOptions={gameOptions}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <TournamentsWidget
            gameOptions={gameOptions}
          />
        </ErrorBoundary>

        {gameOptions.showLicense() && (
          <div className="l6r__menu-license-block">
            <LicenseCaption licenseNumber={gameOptions.getLicenseNumber()} />
          </div>
        )}
      </div>
    </Fragment>
  );
}

MenuForm.propTypes = {
  gameOptions: PropTypes.object.isRequired,
  onAddToFavorites: PropTypes.func.isRequired,
  onFullscreenClick: PropTypes.func.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  onReloadClick: PropTypes.func.isRequired,
  onRemoveFromFavorites: PropTypes.func.isRequired,
  onSupportClick: PropTypes.func.isRequired,
  sendSessionForVwo: PropTypes.func.isRequired,
};

export default MenuForm;
